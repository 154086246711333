import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  BooleanInput,
  useResourceContext,
  useRecordContext,
  SimpleShowLayout,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Button } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import MoneyField from 'components/MoneyField';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import PercentField from 'components/PercentField';
import ProRateField from 'components/ProRateField';
import { camelToSnakeCase, DisplayHelpers } from 'utils/helpers';
import { calcTotalPay } from 'resources/rate_settings/rateSettingsCalculations';
import List from 'components/List';
import { Box } from '@mui/system';
import { RateSettingsDisplayHelper } from 'utils/displayHelpers/rate-settings';

const Filters = [
  <PositionInput alwaysOn source="search.position_ids" multiple clearable />,
  <SpecialtyInput
    alwaysOn
    multiple
    clearable
    source="search.specialty_ids"
    positionSource="search.position_ids"
    optionText=""
  />,
  <BooleanInput alwaysOn label="Valid For Jobs" source="for_job" />,
  <BooleanInput alwaysOn label="Valid For Assignments" source="for_assignment" />,
];

const CreatePositionRateSettingButton = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const recordId = record[`${camelToSnakeCase(resource)}_id`];
  const to = recordId ? `/${resource}/${recordId}/edit` : `/${resource}/create`;
  let display;
  if (record.location_position_rate_setting_id && record.location_id) {
    display = 'Update';
  } else if (record.market_position_rate_setting_id && record.market_id) {
    display = 'Update';
  } else if (record.state_position_rate_setting_id && record.state_id) {
    display = 'Update';
  } else {
    display = 'Create Custom';
  }
  return (
    <FunctionField
      render={() => (
        <Button component={Link} size="small" color="primary" to={to} state={{ record }}>
          {display}
        </Button>
      )}
    />
  );
};

const StatePositionRateSettingsTab = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const parentIdName = `${camelToSnakeCase(resource)}_id`;
  const resourceName = `${resource}PositionRateSetting`;

  function getChargeRateCents(settings) {
    const rateSettingsDisplayHelper = new RateSettingsDisplayHelper(settings);

    return rateSettingsDisplayHelper.getChargeRateCents();
  }

  return (
    <SimpleShowLayout>
      <Box display="flex">
        <Button
          component={Link}
          color="primary"
          to={`/${resourceName}/create`}
          state={{ record: { [parentIdName]: record.id } }}
        >
          Add position rate settings
        </Button>
      </Box>
      <List
        resource={resourceName}
        filters={Filters}
        hasCreate={false}
        filter={{ [parentIdName]: record.id, include_parents: true }}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false}>
          <CreatePositionRateSettingButton />
          <FunctionField
            label="Active for Job"
            render={(v) => (v?.settings.can_post_job ? <Check /> : <Clear />)}
          />
          <FunctionField
            label="Active for Assignment"
            render={(v) => (v?.settings.can_post_assignment ? <Check /> : <Clear />)}
          />
          <LinkField source={parentIdName} />
          {resource === 'State' && <TextField label="State" source="state.name" />}
          <TextField label="Position" source="position.display_name" />
          <TextField label="Specialty" source="specialty.label" />
          <ProRateField label="Pro Rate" />
          <FunctionField
            label="Pro Blended 12hr Rate"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  12,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ) / 12,
              )
            }
          />
          <FunctionField
            label="Pro 12hr Total"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  12,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ),
              )
            }
          />
          <FunctionField
            label="Pro 8hr w/ Lunch Total"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  8,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ),
              )
            }
          />
          <FunctionField
            label="Pro 8hr w/o Lunch Total"
            render={({ settings }) =>
              DisplayHelpers.money(
                calcTotalPay(
                  getChargeRateCents(settings),
                  settings.margin_percent_1099,
                  7.5,
                  settings.ten99_per_diem.charge_max_daily_regular_hours,
                  settings.ten99_per_diem.charge_max_daily_overtime_hours,
                  settings.ten99_per_diem.charge_overtime_multiplier,
                  settings.ten99_per_diem.payout_max_daily_regular_hours,
                  settings.ten99_per_diem.payout_max_daily_overtime_hours,
                  settings.ten99_per_diem.payout_overtime_multiplier,
                ),
              )
            }
          />
          <MoneyField label="Charge 8h rate" source="settings.original_charge_rate_cents" />
          <MoneyField label="Charge blended rate" source="settings.blended_rate_cents" />
          <NumberField
            label="Facility 1099 Assignment Overtime Multiplier"
            source="settings.ten99_assignment.charge_overtime_multiplier"
          />
          <NumberField
            label="Facility 1099 Per Diem Overtime Multiplier"
            source="settings.ten99_per_diem.charge_overtime_multiplier"
          />
          <NumberField
            label="Facility W2 Overtime Multiplier"
            source="settings.w2.charge_overtime_multiplier"
          />
          <NumberField
            label="1099 Assignment Payout Overtime Multiplier"
            source="settings.ten99_assignment.payout_overtime_multiplier"
          />
          <NumberField
            label="1099 Per Diem Payout Overtime Multiplier"
            source="settings.ten99_per_diem.payout_overtime_multiplier"
          />
          <NumberField
            label="W2 Payout Overtime Multiplier"
            source="settings.w2.payout_overtime_multiplier"
          />
          <MoneyField
            label="Charge On Call Rate"
            source="settings.charge_on_call_hourly_rate_cents"
          />
          <PercentField label="W2 Fee Percent" source="settings.w2_fee_percent" />
          <PercentField label="1099 Fee Percent" source="settings.fee_percent" />
          <PercentField label="On Call Fee Percent" source="settings.on_call_fee_percent" />
          <PercentField label="Margin 1099 Percent" source="settings.margin_percent_1099" />
          <PercentField label="Margin W2 Local Percent" source="settings.margin_percent_w2_local" />
          <PercentField
            label="Margin W2 Travel Percent"
            source="settings.margin_percent_w2_travel"
          />
          <PercentField label="Margin W2 TCV Percent" source="settings.margin_percent_w2_tcv" />
          <MoneyField label="Margin W2 TCV" source="settings.margin_w2_tcv_cents" />
          <MoneyField
            label="Assignment Bonus Tier 2 Travel Amount"
            source="settings.assignment_bonus_tier2_travel_amount_cents"
          />
          <MoneyField
            label="Assignment Bonus Tier 2 travel Amount TCV"
            source="settings.assignment_bonus_tier2_travel_amount_tcv_cents"
          />
          <MoneyField
            label="Assignment Bonus Tier 1 Travel Amount"
            source="settings.assignment_bonus_tier1_travel_amount_cents"
          />
          <MoneyField
            label="Assignment Bonus Tier 1 Travel Amount TCV"
            source="settings.assignment_bonus_tier1_travel_amount_tcv_cents"
          />
          <TextField label="Parent" source="id" />
        </Datagrid>
      </List>
    </SimpleShowLayout>
  );
};

export default StatePositionRateSettingsTab;
