import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { Datagrid, BooleanInput, NumberInput, DateInput, FunctionField } from 'react-admin';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import LinkField from 'components/LinkField';
import BulkUploadButtons from 'components/BulkUploadButtons';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';

const LocationPositionRateSettingListFilters = [
  <IdInput />,
  <PositionInput emptyText="All" alwaysOn />,
  <NumberInput label="Location ID" source="location_id" alwaysOn />,
  <BooleanInput label="Below Market/State Rate" alwaysOn source="search.below_market_rate" />,
  <DateInput alwaysOn label="Updated after" source="search.updated_at_from" />,
];

const bulkUploadButtons = (
  <BulkUploadButtons
    downloadUrl="https://medely-public-files.s3.us-west-2.amazonaws.com/csv_templates/location_position_rate_settings_bulk_upload_template.csv"
    uploadUrl="v3/admin/location_position_rate_settings/import"
  />
);

const LocationPositionRateSettingList = () => (
  <List
    filters={LocationPositionRateSettingListFilters}
    exporter={false}
    additionalActions={bulkUploadButtons}
    filter={{ active: true }}
  >
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="Location ID" source="location_id" />
      <LinkField label="Position" source="position.display_name" sortBy="position" />
      <LinkField label="Specialty" source="specialty.label" sortBy="specialty" />
      <MoneyField
        label="Charge 8hr rate"
        source="settings.charge_rate_cents"
        blankIfNull={true}
        sortBy="charge_rate_cents"
      />
      <FunctionField
        label="Charge blended rate"
        render={({ settings }) => {
          return settings.charge_rate_cents * settings.blended_rate_multiplier || '';
        }}
      />
      <DateTimeField format="MM/dd/yyyy HH:mm" source="updated_at" timeZone="America/Los_Angeles" />
    </Datagrid>
  </List>
);

LocationPositionRateSettingList.query = gql`
  query locationPositionRateSettings($input: LocationPositionRateSettingQueryInput) {
    _locationPositionRateSettingsMeta(input: $input) {
      count
    }
    locationPositionRateSettings(input: $input) {
      id
      active
      location_id
      location_position_rate_setting_active
      location_position_rate_setting_id
      market_position_rate_setting_active
      market_position_rate_setting_id
      position {
        id
        display_name
      }
      location {
        primary_billing_group {
          plan
        }
      }
      position_id
      settings {
        agency_charge_rate_cents
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        blended_rate_cents
        blended_rate_multiplier
        can_post_assignment
        can_post_job
        charge_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        charge_overtime_multiplier
        charge_rate_cents
        fee_percent
        margin_percent_1099
        margin_percent_w2_local
        margin_percent_w2_tcv
        margin_percent_w2_travel
        margin_w2_tcv_cents
        on_call_fee_percent
        original_charge_rate_cents
        payout_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_currency
        payout_overtime_multiplier
        ten99_assignment
        ten99_per_diem
        w2
        w2_fee_percent
      }
      specialty {
        id
        label
      }
      specialty_id
      state_position_rate_setting_active
      state_position_rate_setting_id
      updated_at
    }
  }
`;

export default LocationPositionRateSettingList;
