import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { Datagrid, FunctionField, NumberInput } from 'react-admin';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import BulkUploadButtons from 'components/BulkUploadButtons';

const AgencyLocationPositionRateSettingListFilters = [
  <IdInput />,
  <PositionInput emptyText="All" alwaysOn />,
  <NumberInput label="Location ID" source="location_id" alwaysOn />,
  <NumberInput label="Agency ID" source="agency_id" alwaysOn />,
];

const bulkUploadButtons = (
  <BulkUploadButtons
    downloadUrl="https://medely-public-files.s3.us-west-2.amazonaws.com/csv_templates/agency_location_position_rate_settings_template.csv"
    uploadUrl="v3/admin/agency_location_position_rate_settings/import"
  />
);

const AgencyLocationPositionRateSettingList = () => (
  <List
    filters={AgencyLocationPositionRateSettingListFilters}
    exporter={false}
    additionalActions={bulkUploadButtons}
    filter={{ active: true }}
  >
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="Location ID" source="location_id" />
      <LinkField label="Agency ID" source="agency_id" />
      <LinkField label="Position" source="position.display_name" />
      <LinkField label="Specialty" source="specialty.label" />
      <MoneyField label="Charge 8hr rate" source="settings.charge_rate_cents" blankIfNull={true} />
      <FunctionField
        label="Charge blended rate"
        render={({ settings }) => {
          return settings.charge_rate_cents * settings.blended_rate_multiplier || '';
        }}
      />
    </Datagrid>
  </List>
);

AgencyLocationPositionRateSettingList.query = gql`
  query agencyLocationPositionRateSettings($input: AgencyLocationPositionRateSettingQueryInput) {
    _agencyLocationPositionRateSettingsMeta(input: $input) {
      count
    }
    agencyLocationPositionRateSettings(input: $input) {
      id
      location_id
      agency_id
      position_id
      specialty_id
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      settings {
        charge_rate_cents
        blended_rate_cents
        blended_rate_multiplier
        original_charge_rate_cents
      }
      active
    }
  }
`;

export default AgencyLocationPositionRateSettingList;
