import React, { useState, useEffect } from 'react';
import {
  NumberInput,
  BooleanInput,
  SimpleForm,
  useResourceContext,
  useRecordContext,
} from 'react-admin';
import PositionInput from 'components/PositionInput';
import MoneyInput from 'components/MoneyInput';
import SpecialtyInput from 'components/SpecialtyInput';
import StateInput from 'components/StateInput';
import MarketInput from 'components/MarketInput';
import useCheckAccess from 'hooks/useCheckAccess';
import LocationInput from 'components/LocationInput';
import AgencyInput from 'components/AgencyInput';
import FacilityAndProfessionalRateInput from './LocationAndProfessionalRateInput';
import { pick } from 'ramda';
import W2And1099SubSettingsForm from './W2And1099SubSettingsForm';
import DateInput from 'components/DateInput';
import { auth } from 'providers/utils';
import { Divider } from '@medely/web-components';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const EDITABLE_ACTIVE_RESOURCES = ['LocationPositionRateSetting', 'MarketPositionRateSetting'];

const ACTIVE_FIELDS = [
  {
    asset: 'per-diem shifts',
    field: 'can_post_job',
  },
  {
    asset: 'assignments',
    field: 'can_post_assignment',
  },
];

// TODO(FIN-2516): Delete hasLastRateReviewEdit once proper permissions is implemented.
const hasLastRateReviewEdit = () => {
  const APPROVED_EMAILS = [
    'angie@medely.com',
    'dblancett@medely.com',
    'kbogan@medely.com',
    'kera.quintanilla@medely.com',
  ];
  const currentUserEmail = auth.currentUser.email;

  return APPROVED_EMAILS.includes(currentUserEmail);
};

const getRateSource = (record, resource) => {
  if (resource === 'MarketPositionRateSetting' || record?.market_id) {
    return 'market_id';
  } else if (resource === 'StatePositionRateSetting' || record?.state_id) {
    return 'state_id';
  } else if (
    record?.location_id ||
    resource === 'LocationPositionRateSetting' ||
    resource === 'AgencyLocationPositionRateSetting'
  ) {
    if (record?.agency_id || resource === 'AgencyLocationPositionRateSetting') {
      return 'agency_id';
    } else {
      return 'location_id';
    }
  }
  throw new Error('No rate representation found');
};

const getRateReferenceComponent = (source) => {
  if (source === 'market_id') {
    return <MarketInput source="market_id" />;
  } else if (source === 'state_id') {
    return <StateInput source="state_id" />;
  } else if (source === 'location_id') {
    return <LocationInput source="location_id" />;
  } else if (source === 'agency_id') {
    return (
      <>
        <LocationInput source="location_id" />
        <AgencyInput source="agency_id" />
      </>
    );
  }
  return () => null;
};

const formatCents = (cents: number) =>
  !!cents ? (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null;

const ActiveForPostingField = ({ asset, settingsField, field }) => {
  const label = `Active for ${asset}`;
  const { setValue } = useFormContext();
  const resource = useResourceContext();
  const record = useRecordContext();

  const source = `${settingsField}.${field}`;
  const recordValue = record[settingsField]?.[field];

  const [fieldValue, setFieldValue] = useState<boolean>(!!recordValue);
  const [isOverriding, setIsOverriding] = useState<boolean>(false);
  const isUnset = recordValue === null;

  const toggleOverride = () => setIsOverriding(!isOverriding);
  const showField = !isUnset || isOverriding;

  useEffect(() => {
    setValue(source, recordValue);
    setFieldValue(!!recordValue);
  }, [recordValue]);

  useEffect(() => {
    if (!showField) {
      setFieldValue(null);
      setValue(source, null);
    }
  }, [showField]);

  useEffect(() => {
    if (isOverriding) {
      setValue(source, false, { shouldDirty: true, shouldTouch: true });
    }
  }, [isOverriding]);

  const helperTextSuffix = fieldValue
    ? `allowed to post ${asset}`
    : `prevented from posting ${asset}`;
  const helperColor = fieldValue ? 'success.main' : 'error.main';

  return (
    <>
      {isUnset && (
        <>
          <FormControlLabel
            sx={{ width: '100%' }}
            label={`Override "${label}"*`}
            control={<Switch checked={isOverriding} onClick={toggleOverride} />}
          />
          <Typography variant="body2" color="error.main" pb="20px">
            {`* "${label}" is currently unset on this ${resource}, \
            and is being imported from parent rate setting.`}
            <br />
            {`You may change this configuration \
              by checking this "Override" toggle.`}
          </Typography>
        </>
      )}
      {showField && (
        <BooleanInput
          defaultValue={recordValue}
          label={label}
          source={source}
          onClick={() => setFieldValue(!fieldValue)}
          value={!!fieldValue}
          helperText={
            <Typography color={helperColor}>{`Locations will be ${helperTextSuffix}`}</Typography>
          }
        />
      )}
    </>
  );
};

const PositionRateSettingsForm = ({ settingsField = null }: { settingsField: string }) => {
  const record = useRecordContext();
  const isEdit = Boolean(record?.id);
  const resource = useResourceContext();
  const checkAccess = useCheckAccess();
  const source = getRateSource(record, resource);
  const RateReferenceComponent = getRateReferenceComponent(source);

  const inheritedSettings = record?.settings;
  const getInherited = (key: string) =>
    String((inheritedSettings ? inheritedSettings[key] : null) || '');

  const defaultValues =
    record &&
    pick(
      [
        record && 'id',
        'position_id',
        'state_id',
        'location_id',
        'specialty_id',
        'active',
        settingsField,
      ],
      record,
    );

  const showPostAndAgencyRates = isEdit && EDITABLE_ACTIVE_RESOURCES.includes(resource);

  return (
    <SimpleForm defaultValues={defaultValues}>
      {RateReferenceComponent}
      <PositionInput />
      <SpecialtyInput source="specialty_id" positionSource="position_id" optionText="" />
      <BooleanInput label="Active" source="active" />
      {showPostAndAgencyRates && (
        <Box width="100%">
          <Divider boxProps={{ sx: { paddingY: '20px' } }} />
          {ACTIVE_FIELDS.map(({ asset, field }) => (
            <>
              <ActiveForPostingField
                key={`active-field-for-${asset}`}
                asset={asset}
                settingsField={settingsField}
                field={field}
              />
              <Divider boxProps={{ sx: { paddingY: '20px' } }} />
            </>
          ))}
        </Box>
      )}
      {resource === 'MarketPositionRateSetting' && hasLastRateReviewEdit() && (
        <DateInput label="Last Review Date" source="last_rate_review" />
      )}
      <FacilityAndProfessionalRateInput
        source={settingsField}
        inheritedSettings={inheritedSettings}
      />
      <MoneyInput
        label="Location On Call Rate"
        source={`${settingsField}.charge_on_call_hourly_rate_cents`}
        helperText={formatCents(inheritedSettings?.charge_on_call_hourly_rate_cents)}
      />
      <NumberInput
        label="On Call Percentage Fee"
        source={`${settingsField}.on_call_fee_percent`}
        helperText={getInherited('on_call_fee_percent')}
      />
      <NumberInput
        label="Margin 1099 Percent"
        source={`${settingsField}.margin_percent_1099`}
        helperText={getInherited('margin_percent_1099')}
      />
      <NumberInput
        label="Margin W2 Local Percent"
        source={`${settingsField}.margin_percent_w2_local`}
        helperText={getInherited('margin_percent_w2_local')}
      />
      <NumberInput
        label="Margin W2 Travel Percent"
        source={`${settingsField}.margin_percent_w2_travel`}
        helperText={getInherited('margin_percent_w2_travel')}
      />
      <NumberInput
        label="Margin W2 TCV Percent"
        source={`${settingsField}.margin_percent_w2_tcv`}
        helperText={getInherited('margin_percent_w2_tcv')}
      />
      <MoneyInput
        label="Margin W2 TCV"
        source={`${settingsField}.margin_w2_tcv_cents`}
        helperText={formatCents(inheritedSettings?.margin_w2_tcv_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 2 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier2_travel_amount_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 2 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier2_travel_amount_tcv_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier2_travel_amount_tcv_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 1 Travel Amount"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier1_travel_amount_cents)}
      />
      <MoneyInput
        label="Assignment Bonus Tier 1 Travel Amount TCV"
        source={`${settingsField}.assignment_bonus_tier1_travel_amount_tcv_cents`}
        helperText={formatCents(inheritedSettings?.assignment_bonus_tier1_travel_amount_tcv_cents)}
      />
      {showPostAndAgencyRates && (
        <MoneyInput
          label="Agency Charge Rate"
          source={`${settingsField}.agency_charge_rate_cents`}
          helperText={formatCents(inheritedSettings?.agency_charge_rate_cents)}
        />
      )}

      {resource !== 'StatePositionRateSetting' && checkAccess('update', resource, 'settings') && (
        <W2And1099SubSettingsForm source={settingsField} inheritedSettings={inheritedSettings} />
      )}
    </SimpleForm>
  );
};

export default PositionRateSettingsForm;
