interface RateSettingTaxes {
  original_charge_rate_settings?: number;
  blended_rate_cents?: number;
  ten99_per_diem: {
    charge_max_daily_regular_hours?: number;
  };
  w2: {
    charge_max_daily_regular_hours?: number;
  };
}

export class RateSettingsDisplayHelper {
  constructor(private rateSetting: RateSettingTaxes) {}

  getActiveRate(plan: 'standard' | 'enterprise_w2'): '8-hour-rate' | 'blended-rate' {
    let chargeMaxDailyRegularHours;

    if (plan === 'standard') {
      chargeMaxDailyRegularHours = this.rateSetting.ten99_per_diem.charge_max_daily_regular_hours;
    } else if (plan === 'enterprise_w2') {
      chargeMaxDailyRegularHours = this.rateSetting.w2.charge_max_daily_regular_hours;
    } else {
      throw new Error('Unknown billing group plan');
    }

    return chargeMaxDailyRegularHours === 8 ? '8-hour-rate' : 'blended-rate';
  }

  getChargeRateCents() {
    const activeRate = this.getActiveRate('standard');
    if (activeRate === '8-hour-rate') {
      return this.rateSetting.original_charge_rate_settings;
    }

    return this.rateSetting.blended_rate_cents;
  }
}
