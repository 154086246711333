import React, { useEffect, useRef } from 'react';
import MoneyInput from 'components/MoneyInput';
import { calcPayoutRate, calc1099Margin } from 'resources/rate_settings/rateSettingsCalculations';
import { useFormContext } from 'react-hook-form';
import { mergeDeepWith } from 'ramda';
import { NumberInput, useRecordContext } from 'react-admin';

type Props = {
  source: string;
  inheritedSettings: any;
};

const getSource = (sourceKey, source) => {
  if (sourceKey) {
    return `${sourceKey}.${source}`;
  }
  return source;
};

function RatesField({ label, settingsField, field }) {
  const { setValue, watch } = useFormContext();
  const values = watch();
  const isFocusing = useRef(null);

  const InputComponent = field === 'blended_rate_multiplier' ? NumberInput : MoneyInput;
  const source = `${settingsField}.${field}`;
  const inputValue = values[settingsField]?.[field];
  const chargeRateCentsValue = values[settingsField]?.original_charge_rate_cents / 100;

  function formatNumber(num) {
    return Number.isInteger(num) ? String(num) : num.toFixed(1);
  }

  useEffect(() => {
    if (!inputValue) {
      return;
    }

    const blendedRateCentsField = 'blended_rate_cents';
    const blendedRateMultiplierField = 'blended_rate_multiplier';

    if (
      field === blendedRateCentsField &&
      chargeRateCentsValue &&
      isFocusing.current === blendedRateCentsField
    ) {
      const formattedValue = inputValue / 100;

      setValue(
        `${settingsField}.blended_rate_multiplier`,
        formatNumber(formattedValue / chargeRateCentsValue),
      );

      return;
    }

    if (
      field === blendedRateMultiplierField &&
      chargeRateCentsValue &&
      isFocusing.current === blendedRateMultiplierField
    ) {
      const newBlendedRateCents = chargeRateCentsValue * 100 * inputValue;
      setValue(`${settingsField}.blended_rate_cents`, newBlendedRateCents);

      return;
    }
  }, [inputValue]);

  return (
    <InputComponent
      value={inputValue}
      label={label}
      source={source}
      onChange={(e) => setValue(source, e.target.value)}
      onFocus={() => {
        isFocusing.current = field;
      }}
      onBlur={() => {
        isFocusing.current = null;
      }}
    />
  );
}

const LocationAndProfessionalRateInput = ({ source, inheritedSettings = {} }: Props) => {
  const context = useRecordContext();
  const { watch, setValue } = useFormContext();
  const formData = watch(source) ?? {};
  const defaultValue = useRef(null);
  const isCreate = !context;

  const mergedSettings = mergeDeepWith((a, b) => a ?? b, formData, inheritedSettings);
  const perDiemSettings = mergedSettings.ten99_per_diem ?? {};
  const chargeRateCents =
    perDiemSettings.charge_max_daily_regular_hours === 8
      ? mergedSettings.original_charge_rate_cents
      : mergedSettings.charge_rate_cents;

  useEffect(() => {
    const value = calcPayoutRate(
      chargeRateCents,
      mergedSettings.margin_percent_1099,
      perDiemSettings.charge_max_daily_regular_hours,
      perDiemSettings.charge_max_daily_overtime_hours,
      perDiemSettings.charge_overtime_multiplier,
      perDiemSettings.payout_max_daily_regular_hours,
      perDiemSettings.payout_max_daily_overtime_hours,
      perDiemSettings.payout_overtime_multiplier,
    );
    if (value > 0) {
      // When useEffect is called on load, pro_rate_cents isn't registered with the form.
      // So we use defaultValue to prefill the field.
      defaultValue.current = value;
      setValue(getSource(source, 'pro_rate_cents'), value);
    }
  }, [
    setValue,
    source,
    chargeRateCents,
    mergedSettings.margin_percent_1099,
    perDiemSettings.charge_max_daily_regular_hours,
    perDiemSettings.charge_max_daily_overtime_hours,
    perDiemSettings.charge_overtime_multiplier,
    perDiemSettings.payout_max_daily_regular_hours,
    perDiemSettings.payout_max_daily_overtime_hours,
    perDiemSettings.payout_overtime_multiplier,
  ]);

  const onProRateBlur = () => {
    const value = calc1099Margin(
      chargeRateCents,
      mergedSettings.pro_rate_cents,
      perDiemSettings.charge_max_daily_regular_hours,
      perDiemSettings.charge_max_daily_overtime_hours,
      perDiemSettings.charge_overtime_multiplier,
      perDiemSettings.payout_max_daily_regular_hours,
      perDiemSettings.payout_max_daily_overtime_hours,
      perDiemSettings.payout_overtime_multiplier,
    );
    if (value > 0) {
      setValue(getSource(source, 'margin_percent_1099'), value);
    }
  };

  return (
    <>
      <MoneyInput label="Location Rate" source={getSource(source, 'charge_rate_cents')} />
      <MoneyInput
        label="Professional Rate"
        defaultValue={defaultValue.current}
        source={getSource(source, 'pro_rate_cents')}
        helperText={isCreate ? 'Auto calculation needs 1099 settings filled' : null}
        onBlur={onProRateBlur}
      />

      <RatesField settingsField={source} label="8 hour rate" field={'original_charge_rate_cents'} />

      <RatesField label="Blended rate" settingsField={source} field={'blended_rate_cents'} />

      <RatesField
        label="Blended rate multiplier"
        settingsField={source}
        field={'blended_rate_multiplier'}
      />
    </>
  );
};

export default LocationAndProfessionalRateInput;
