import { Box, Typography } from '@mui/material';
import React from 'react';
import { ProfessionalQualificationAttribute } from 'resources/professional_qualification_attributes/utils';

const TypeformShow = ({ record }) => {
  const jsonAttributes = record.professional_qualification_attributes?.filter(
    (pqa) => new ProfessionalQualificationAttribute(pqa).isJson,
  );
  const questions = jsonAttributes?.find((pqa) =>
    pqa.qualification_attribute.slug.toLowerCase().includes('question'),
  );
  const answers = jsonAttributes?.find((pqa) =>
    pqa.qualification_attribute.slug.toLowerCase().includes('answer'),
  );

  if (!questions) {
    return null;
  }

  return (
    <>
      {questions.value?.map((question, index) => {
        const answer = answers.value[index];
        const fieldType = answer.type;
        return (
          <Box my={2}>
            <Typography variant="body2">{question.title}</Typography>
            <Typography variant="caption">{answer[fieldType].toString()}</Typography>
          </Box>
        );
      })}
    </>
  );
};

export default TypeformShow;
